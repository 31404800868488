import React from 'react'
import {
  Card, Typography, Box, Divider,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Image from '@components/atoms/image'
import ButtonCta from '@components/atoms/button-cta'
import useStyles from './card-links.styles'

const CardLinks = (props) => {
  const {
    promotedLinks,
  } = props
  const styles = useStyles()

  return (
    <Card className={styles.root}>
      {promotedLinks.image && promotedLinks.image.url && <Image className={styles.image} src={`${promotedLinks.image.url}?h=200&w=600&fit=fill&fl=progressive&q=80`} alt={promotedLinks?.headline} />}
      <div className={styles.contentContainer}>
        <Box className={styles.headlineWrapper}>
          {promotedLinks && <Typography variant="h5" variantMapping={{ h5: 'h3' }} className={styles.headline}>{promotedLinks?.headline}</Typography>}
        </Box>
        <Divider className={styles.divider} />
        {promotedLinks && promotedLinks.linksCollection.items.map((el) => {
          return (
            <ButtonCta
              arrow
              className={styles.link}
              key={el.text}
              externalLink={el.externalLink}
              reference={el.link}
            >
              {el.text}
            </ButtonCta>
          )
        })}
      </div>
    </Card>
  )
}

CardLinks.propTypes = {
  promotedLinks: PropTypes.object,
}

export default CardLinks
