import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from '@components/error'
import { leaderboardFragment } from '@layouts/leaderboard/leaderboard-fragment'
import LeaderboardLanding from './index'

export const externalLeaderboardFragment = `
  headline
  tourName
  status
  location
  dates
  externalUrl
`

const QUERY = gql`
query LeaderboardLandingPage($preview: Boolean)  {
    leaderboardLandingCollection(limit: 1, preview: $preview)  {
    items {
      title
      metaTitle
      metaDescription
      metaImage {
        url
      }
      newsletterSignup {
        headline
        emailHeadline
        emailDescription
        showSubscribe
        socialHeadline
        socialDescription
        socialCollection {
          items {
            title
            link
            headline
          }
        }
        background {
          url
        }
      }
      leaderboardsCollection {
        items {
          ${leaderboardFragment}
        }
      }
      externalLeaderboardsCollection {
        items {
          ${externalLeaderboardFragment}
        }
      }
    }
  }
}
`

export default function LeaderboardLandingWithData(props) {
  const { query } = props
  return (
    <Query query={QUERY} variables={query}>
      {({
        loading, error, data,
      }) => {
        if (error) return <Error error={error} displayErrorType="page" />
        if (loading) return <LinearProgress color="secondary" />

        const response = data.leaderboardLandingCollection && data.leaderboardLandingCollection.items[0]

        return response ? (
          <LeaderboardLanding {...response} {...props} />
        ) : <Error statusCode={404} />
      }}
    </Query>
  )
}

LeaderboardLandingWithData.propTypes = {
  query: PropTypes.object,
}
