import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    backgroundColor: theme.palette.background.primary,
    overflow: 'hidden',
    paddingBottom: theme.spacing(2),
  },
  imageContainer: ({ images }) => ({
    backgroundImage: images && `url(${images.lg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '50vh',
    [theme.breakpoints.down('xs')]: {
      backgroundImage: images && `url(${images.sm})`,
    },
  }),
  videoContainer: {
    objectFit: 'cover',
    width: '100%',
    height: '50vh',
  },
  contentContainer: {
    paddingTop: theme.spacing(5),
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  logoWrapper: {
    position: 'absolute',
    top: '-5.9375rem',
    [theme.breakpoints.down('sm')]: {
      top: '-5rem',
    },
    [theme.breakpoints.down('xs')]: {
      top: '-4rem',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  logo: {
    height: '11.875rem',
    width: '11.875rem',
    border: '7px solid white',
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    flexShrink: 0,
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    justifyContent: 'center',
    objectFit: 'cover',
    boxShadow: '0px 4px 36px 0px #000A1621',
    padding: '1px',
    [theme.breakpoints.down('sm')]: {
      height: '10rem',
      width: '10rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: '8rem',
      width: '8rem',
    },
  },
  contentWrapper: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(6),
    width: 'calc(100% - 408px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(6),
      flexDirection: 'column-reverse',
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(6),
    },
  },
  overline: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(5),
    },
  },
  detailsContainer: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3),
    },
  },
  detailsHeadline: {
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  detailsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& >:first-child': {
      paddingLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  detail: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0,
      paddingRight: 0,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    minWidth: '40px',
    [theme.breakpoints.down('sm')]: {
      scale: '0.9',
    },
    [theme.breakpoints.down('xs')]: {
      scale: '0.5',
      marginRight: 0,
    },
  },
  divider: {
    backgroundColor: theme.palette.grey.light,
    width: '1px',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  sectionDivider: {
    backgroundColor: theme.palette.grey.light,
    height: '1px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))
