import { makeStyles } from '@material-ui/core/styles'
import { eyebrowFont } from '@config/mui-theme'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(6),
      },
    },
    overline: {
      color: theme.palette.grey.dark,
      lineHeight: '14px',
    },
    subtitle: {
      marginBottom: '3.125rem',
    },
    image: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
    spotlightImage: {
      height: 'auto',
      width: '100%',
      objectFit: 'contain',
      [theme.breakpoints.down('sm')]: {
        height: '100%',
        objectFit: 'cover',
      },
    },
    gridContainer: {
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row-reverse',
        minHeight: '33.125rem',
      },
    },
    multiContainer: {
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        gap: `${theme.spacing(6)}px`,
        flexDirection: 'row',
      },
      '& > div': {
        flex: `0 0 calc(50% - ${theme.spacing(3)}px)`,
      },
    },
    imageContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      height: '100%',
      margin: `0 -${theme.spacing(2)}px`,
      [theme.breakpoints.up('sm')]: {
        height: '200px',
        margin: `0 -${theme.spacing(3)}px`,
      },
      [theme.breakpoints.up('md')]: {
        margin: `0 0 -${theme.spacing(4)}px 0`,
      },
      [theme.breakpoints.up('lg')]: {
        margin: `0 0 -${theme.spacing(4)}px 0`,
      },
    },
    imageContainerMulti: {
      flexGrow: '1',
      height: 'auto',
      [theme.breakpoints.down('sm')]: {
        height: '200px',
      },
    },
    button: {
      fontFamily: eyebrowFont,
      fontWeight: '700',
      letterSpacing: '0.4px',
      borderRadius: '4px',
      height: 'inherit',
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
      '&.MuiButton-contained': {
        boxShadow: theme.shadows[2],
      },

      [theme.breakpoints.only('xs')]: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      },
    },
    headerLine: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    headerCta: {
      padding: 0,
    },
    mCta: {
      display: 'flex',
      justifyContent: 'center',
    },
    previewContainer: {
      display: 'flex',
      gap: '2rem',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '0',
      },
    },
    previewimageContainer: {
      flexBasis: '200px',
      height: '125px',
      width: '200px',
      [theme.breakpoints.down('sm')]: {
        height: '250px',
        width: '100%',
      },
    },
    leftGrid: {
      height: '100%',
      flexDirection: 'column',
      display: 'flex',
    },
    rightGrid: {
      height: '100%',
      flexDirection: 'column',
      display: 'flex',
      gap: '2rem',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    previewNoImageContainer: {
      flexGrow: '1',
      flexBasis: '0',
      minHeight: '100px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    previewHeadline: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '1rem',
      },
    },
    previewCta: {
      paddingLeft: '0',
    },
    mediumCta: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(6),
      },
    },
    divider: {
      height: '1px',
      backgroundColor: theme.palette.primary.light,
    },
    container: {
      display: 'flex',
    },
    contentContainer: {
      padding: '4.4275rem 5.9375rem',
    },
  }
})

export default useStyles
