import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
  },
  logoSectionWrapper: {
    background: '#EFEFEF',
  },
  sliderContainer: {
    '&>div': { // partner logos root
      paddingTop: 0,
      paddingBottom: 0,
      [theme.breakpoints.down('xs')]: {
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: theme.spacing(4),
      },
    },
    '&>div>div>div:nth-child(1)': { // slider headline
      paddingBottom: 0,
      paddingTop: theme.spacing(4),
    },
    '&>div>div>div:nth-child(2)>div': { // keen slider, card parent
      paddingTop: 0,
      paddingBottom: 0,
      [theme.breakpoints.down('xs')]: {
      },
      [theme.breakpoints.up('sm')]: {
        overflow: 'hidden !important',
      },
    },
  },
  topSliderContainer: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(2),
    '&>div>div>div:nth-child(1)': {
      '&>h4': theme.typography.h3,
    },
    '&>div>div>div:nth-child(2)>div>div>div>div': { // card root
      [theme.breakpoints.up('sm')]: {
        scale: '.9',
        width: '200px',
        padding: '30px',
      },
    },
  },
  bottomWrapper: {
    backgroundColor: '#EFEFEF',
  },
  bottomSliderContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(0),
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      gap: `${theme.spacing(2)}px`,
    },
    '&>div>div': { // slider Container
      paddingBottom: theme.spacing(2),
      height: '100%',
      backgroundColor: '#EFEFEF',
      justifyContent: 'space-between',
    },
    '&>div>div>div:nth-child(1)': { // slider headline
      '&>h4': theme.typography.h5,
    },
    '&>div>div>div:nth-child(2)>div:nth-child(2)': { // slider controls container
      [theme.breakpoints.down('xs')]: {
        marginTop: '-50px',
      },
    },
    '&>div>div>div:nth-child(2)>div>div>div>div': { // card root
      [theme.breakpoints.down('xs')]: {
        scale: '0.6',
        transform: 'translateY(-20%)',
      },
      [theme.breakpoints.up('sm')]: {
        scale: '.9',
        width: '190px',
        padding: '30px',
      },
    },
  },
  hide: {
    display: 'none',
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.grey.light,
  },
  legacyWrapper: {
    background: 'white',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(0),
    '&>div': {
      paddingBottom: 0,
    },
    '&>div>div>div>div>div': {
      paddingBottom: 0,
    },
  },
  articlesWrapper: {
    '&>div': {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
    },
  },
}))

export default useStyles
