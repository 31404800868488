import PropTypes from 'prop-types'
import ButtonCta from '@components/atoms/button-cta'
import useStyles from './live-leaderboard-navigation.styles'

const LiveLeaderboardNavigationLink = (props) => {
  const {
    href,
    linkText,
  } = props
  const styles = useStyles()

  return (
    <ButtonCta className={styles.link} href={href} arrow>{linkText}</ButtonCta>
  )
}

LiveLeaderboardNavigationLink.propTypes = {
  href: PropTypes.string,
  linkText: PropTypes.string,
}

LiveLeaderboardNavigationLink.defaultProps = {
  linkText: 'View the leaderboard',
}

export default LiveLeaderboardNavigationLink
