import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '408px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.palette.shadow.cardBoxShadow,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: '288px',
    },
  },
  image: {
    width: '100%',
    height: '145px',
    objectFit: 'cover',
    display: 'flex',
    justifyContent: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: `${theme.spacing(4)}px ${theme.spacing(4)}px`,
    height: '270px',
  },
  headline: {
    color: theme.palette.secondary.light,
  },
  divider: {
    backgroundColor: theme.palette.grey.light,
    width: '100%',
    height: '1px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  link: {
    color: theme.palette.text.primary,
    padding: 0,
    paddingBottom: theme.spacing(1),
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    '&>span': {
      position: 'relative',
      width: '100%',
    },
    '&>span>svg': {
      position: 'absolute',
      right: 0,
      color: theme.palette.primary.dark,
    },
  },
}))

export default useStyles
