import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconLiveNew from '@components/atoms/icons/icon-live-new'
import renderLink from '@components/renderers/render-link'
import useStyles from './live-leaderboard-navigation.styles'
import LiveLeaderboardNavigationLink from './live-leaderboard-navigation-link'

const LiveLeaderboardNavigation = (props) => {
  const {
    slug,
    fullLeaderboardUrl,
    fullCoverageUrl,
    tournamentId,
  } = props
  const styles = useStyles()

  const linkProps = renderLink({ slug, type: 'Leaderboard' })

  return (
    <Box py={6} className={styles.root}>
      <Box className={styles.background}>
        <Box className={styles.flexLeft}>
          <IconLiveNew viewBox="0 0 28 28" /> <Typography variant="h5" variantMapping={{ h5: 'h2' }} className={styles.header}>Live Leaderboard Updates</Typography>
        </Box>
        {fullLeaderboardUrl && <LiveLeaderboardNavigationLink href={fullLeaderboardUrl} />}
        {!fullLeaderboardUrl && tournamentId && (
          <LiveLeaderboardNavigationLink href={linkProps.href} />
        )}
        {!fullLeaderboardUrl && !tournamentId && fullCoverageUrl && (
          <LiveLeaderboardNavigationLink href={fullCoverageUrl} linkText="View Coverage" />
        )}
      </Box>
    </Box>
  )
}

LiveLeaderboardNavigation.propTypes = {
  slug: PropTypes.string,
  fullLeaderboardUrl: PropTypes.string,
  fullCoverageUrl: PropTypes.string,
  tournamentId: PropTypes.string,
}

LiveLeaderboardNavigation.defaultProps = {}

export default LiveLeaderboardNavigation
