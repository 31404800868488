import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import PropTypes from 'prop-types'
import useStyles from './landing-page-header.styles'

const LandingPageHeader = (props) => {
  const {
    headline,
  } = props
  const styles = useStyles()

  return (
    <Box>
      <Box py="1.8rem">
        <Container className={styles.wrapper}>
          <Typography
            variant="h2"
            variantMapping={{ h2: 'h1' }}
            className={styles.heading}
          >{headline}
          </Typography>
        </Container>
      </Box>
    </Box>
  )
}

LandingPageHeader.propTypes = {
  headline: PropTypes.string,
}

LandingPageHeader.defaultProps = {}

export default LandingPageHeader
