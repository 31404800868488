import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
  },
  background: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem',
    borderRadius: '8px',
    width: '52.75rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  header: {
    color: theme.palette.gold.main,
  },
  flexLeft: {
    display: 'flex',
    gap: '1rem',
    marginTop: 'auto',
    marginBottom: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  link: {
    padding: '0',
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white,
    },
    '&>span>svg': {
      color: theme.palette.gold.main,
    },
  },
}))

export default useStyles
