import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    display: 'inline-block',
    borderBottom: `6px solid ${theme.palette.gold.main}`,
  },
}))

export default useStyles
