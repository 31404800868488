import PropTypes from 'prop-types'
import RenderRichText from '@components/renderers/render-rich-text'
import Image from '@components/atoms/image'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useStyles from './event-legacy-spotlight.styles'


const EventLegacySpotlight = (props) => {
  const {
    legacySpotlightHeadline,
    legacySpotlightRichText,
    legacySpotlightImage,
  } = props
  const styles = useStyles()

  return (
    <Box bgcolor="common.white">
      <Container className={styles.root}>
        <Grid className={styles.gridContainer} container direction="column-reverse">
          <Grid item xs={12} md={6} lg={5}>
            <Box pb={4} pl={[0, 0, 4]}>
              <Box>
                <Box display={{ xs: 'none', md: 'block' }} mb="3.125rem">
                  <Typography
                    variant="overline"
                    className={styles.overline}
                  >A Piece of PGA History
                  </Typography>
                </Box>
                <Box mb=".875rem" mt={[2, 2, 0]} color="secondary.light">
                  <Typography variant="h3" variantMapping={{ h3: 'h2' }} className={styles.headline}>{legacySpotlightHeadline}</Typography>
                </Box>
                <RenderRichText {...legacySpotlightRichText} />
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} lg={7}>
            <Box display={{ xs: 'block', md: 'none' }}>
              <Typography
                variant="overline"
                className={styles.overline}
                style={{ textAlign: 'center', marginBottom: '1.75rem' }}
              >A Piece of PGA History
              </Typography>
            </Box>
            <Box className={styles.imageContainer} mb={[4, 4, 0]}>
              {legacySpotlightImage && (
                <Image
                  className={styles.spotlightImage}
                  src={`${legacySpotlightImage.url}?h=600&w=900&fit=fill&fl=progressive&q=80`}
                  alt={legacySpotlightImage.description || ''}
                />
              )
              }
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

EventLegacySpotlight.propTypes = {
  legacySpotlightHeadline: PropTypes.string,
  legacySpotlightRichText: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  legacySpotlightImage: PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string,
  }),
}

EventLegacySpotlight.defaultProps = {}

export default EventLegacySpotlight
