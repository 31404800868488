import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import defaults from '@config/defaults'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import StayConnectedSecondary from '@components/stay-connected-secondary'
import ErrorBoundary from '@components/error/boundary'
import { LeaderboardQuery } from '@components/leaderboard/index'
import LeaderboardHeadline from '@components/leaderboard/leaderboard-headline'
import ParentLeaderboardPage from '@layouts/leaderboard/parent-leaderboard-page'
import { ScrambleLeaderboardQuery } from '@components/leaderboard-scramble/index'
import { TeamLeaderboardQuery } from '@components/leaderboard-team/team-leaderboard.query'
import LeaderboardLandingHeader from './leaderboard-landing-header'
import useStyles from './leaderboard-landing.styles'

const LeaderboardLanding = (props) => {
  const {
    title,
    newsletterSignup,
    metaTitle,
    metaDescription,
    metaImage,
    leaderboardsCollection,
    externalLeaderboardsCollection,
  } = props

  const styles = useStyles()
  const router = useRouter()

  if (!router) return null

  return (
    <div>
      <NextSeo
        noindex={defaults.noindexNofollow}
        nofollow={defaults.noindexNofollow}
        title={metaTitle}
        description={metaDescription}
        canonical={`${defaults.baseUrl}leaderboards`}
        openGraph={{
          title: metaTitle,
          type: 'website',
          url: `${defaults.baseUrl}${router.asPath.substring(1)}`, // trimming string because of /
          images: [{ url: (metaImage && metaImage.url) }],
        }}
      />
      <LeaderboardLandingHeader headline={title} />
      <ErrorBoundary>
        <Box py={4} className={styles.leaderboardsWrapper}>
          {externalLeaderboardsCollection.items && externalLeaderboardsCollection.items.map(item => (
            <Box key={item.headline} mb={[6, 6, 8]}>
              <LeaderboardHeadline {...item} />
            </Box>
          ))}
          {leaderboardsCollection.items && leaderboardsCollection.items.map((item) => {
            switch (item.format) {
            case 'scramble':
              return (
                <div className={styles.leaderboardContainer} key={item.tournamentId}>
                  <LeaderboardHeadline {...item} />
                  <ParentLeaderboardPage {...item}>
                    <ScrambleLeaderboardQuery
                      {...item}
                      limit={Number(item.leaderboardPlaces) || null}
                    />
                  </ParentLeaderboardPage>
                </div>
              )
            case 'team/individual':
              return (
                <div className={styles.leaderboardContainer} key={item.tournamentId}>
                  <LeaderboardHeadline {...item} />
                  <ParentLeaderboardPage {...item}>
                    <TeamLeaderboardQuery
                      {...item}
                      limit={Number(item.leaderboardPlaces) || null}
                    />
                  </ParentLeaderboardPage>
                </div>
              )
            default:
              return (
                <div className={styles.leaderboardContainer} key={item.tournamentId}>
                  <LeaderboardHeadline {...item} />
                  <LeaderboardQuery
                    {...item}
                    id={item.tournamentId}
                    limit={Number(item.leaderboardPlaces) || null}
                    enableScorecard
                  />
                </div>
              )
            }
          })}
        </Box>
      </ErrorBoundary>
      {newsletterSignup && (
        <ErrorBoundary>
          <Box>
            <StayConnectedSecondary
              signup={newsletterSignup}
            />
          </Box>
        </ErrorBoundary>
      )}
    </div>
  )
}

LeaderboardLanding.propTypes = {
  title: PropTypes.string,
  newsletterSignup: PropTypes.shape({
    headline: PropTypes.string,
    emailHeadline: PropTypes.string,
    emailDescription: PropTypes.string,
    showSubscribe: PropTypes.bool,
    socialHeadline: PropTypes.string,
    socialDescription: PropTypes.string,
    socialCollection: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          link: PropTypes.string,
          headline: PropTypes.string,
        }),
      ),
    }),
  }),
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  metaImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  leaderboardsCollection: PropTypes.shape({
    items: PropTypes.array,
  }),
  externalLeaderboardsCollection: PropTypes.shape({
    items: PropTypes.array,
  }),
}

LeaderboardLanding.defaultProps = {}

export default LeaderboardLanding
