import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  outer: {
    position: 'fixed',
    width: '100%',
    top: 90,
    backgroundColor: 'white',
    zIndex: 99,
    [theme.breakpoints.down('sm')]: {
      top: 45,
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 15,
    },
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  clockWrapper: {
    position: 'relative',
    top: 4,
  },
  heading: {
    display: 'inline-block',
    borderBottom: `6px solid ${theme.palette.gold.main}`,
  },
  leaderboardContainer: {
    display: 'flex',
    flexDirection: 'column',
    '&:not(:last-child)': {
      marginBottom: '5.125rem',
    },
  },
  leaderboardsWrapper: {
    marginTop: '120px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '163px',
    },
  },
}))

export default useStyles
