import PropTypes from 'prop-types'
import Video from '@components/atoms/video'
import {
  Container, Typography, Box, Divider,
} from '@material-ui/core'
import DateRange from '@components/atoms/date-range-new'
import {
  IconFlagFilledOutlined,
  IconDateFilledOutlined,
  IconMarkerOutlinePlusFill,
} from '@components/atoms/icons'
import Image from '@components/atoms/image'
import CardLinks from '@components/card-links'
import { RenderRichText } from '@components/renderers/index'
import { useStyles } from './hero-event-details.styles'

const Hero = (props) => {
  const {
    heroImage,
    heroImageMobile,
    logo,
    eventHeadline,
    eventRichText,
    startDate,
    endDate,
    course,
    location,
    promotedLinks,
  } = props

  const videoBackground = heroImage && heroImage.url.includes('video') && heroImage.url

  const images = {
    sm: heroImageMobile
      ? `${heroImageMobile.url}?fm=jpg&fl=progressive&q=80`
      : heroImage && `${heroImage.url}?fm=jpg&fl=progressive&q=80`,
    lg: heroImage
      ? `${heroImage?.url}?fm=jpg&h=1200&w=1800&fit=fill&fl=progressive&q=80`
      : '',
  }

  const styles = useStyles({ images })

  return (
    <Box
      className={styles.root}
      display="flex"
      flexDirection="column"
      data-cy="events-page-hero"
    >
      {videoBackground ? (
        <Video
          src={videoBackground}
          autoPlay
          muted
          loop
          playsinline
          className={styles.videoContainer}
        />
      ) : (
        <Box className={styles.imageContainer} />
      )}
      <Container className={styles.contentContainer}>
        <Box className={styles.logoWrapper}>
          {logo && logo.url && <Image className={styles.logo} src={`${logo.url}?fm=jpg&h=200&w=200&fit=fill&fl=progressive&q=80`} alt="" />}
        </Box>
        <Box className={styles.contentWrapper}>
          <Box>
            {eventHeadline && <Typography variant="h4" variantMapping={{ h4: 'h2' }} className={styles.overline}>{eventHeadline}</Typography>}
            <Box className={styles.description}>
              <RenderRichText {...eventRichText} />
            </Box>
          </Box>
          <Divider className={styles.sectionDivider} />
          <Box className={styles.detailsContainer}>
            <Typography variant="h6" variantMapping={{ h6: 'h3' }} className={styles.detailsHeadline}>Event Details</Typography>
            <Box className={styles.detailsWrapper}>
              <div className={styles.detail}>
                <IconDateFilledOutlined className={styles.icon} title="date" />
                <Typography variant="body1">
                  <DateRange startDate={startDate} endDate={endDate} />
                </Typography>
              </div>
              <Divider className={styles.divider} />
              <div className={styles.detail}>
                <IconFlagFilledOutlined className={styles.icon} title="course" />
                <Typography variant="body1">{course}</Typography>
              </div>
              <Divider className={styles.divider} />
              <div className={styles.detail}>
                <IconMarkerOutlinePlusFill className={styles.icon} title="location" />
                <Typography variant="body1">{location}</Typography>
              </div>
            </Box>
          </Box>
        </Box>
        {promotedLinks && (
          <CardLinks
            promotedLinks={promotedLinks}
          />
        )
        }
      </Container>
    </Box>
  )
}

Hero.propTypes = {
  heroImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  heroImageMobile: PropTypes.shape({
    url: PropTypes.string,
  }),
  logo: PropTypes.shape({
    url: PropTypes.string,
  }),
  eventHeadline: PropTypes.string,
  eventRichText: PropTypes.object,
  course: PropTypes.string,
  location: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  promotedLinks: PropTypes.object,
}

Hero.defaultProps = {}

export default Hero
