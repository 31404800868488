import PropTypes from 'prop-types'
import LinearProgress from '@material-ui/core/LinearProgress'
import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import Error from '@components/error'
import Event, { eventFragment } from '@layouts/event/'

export const eventSlugQuery = fragment => gql`
query BySlug($slug: String, $preview: Boolean) {
  eventCollection(limit: 1, preview: $preview, where: {slug: $slug}) {
    total
    items {
      ${fragment}
    }
  }
}`

export const eventIdQuery = fragment => gql`
query BySlug($id: String!) {
  event(id: $id) {
    ${fragment}
  }
}`

const EventPage = (props) => {
  const { query } = props

  return (
    <Query query={query.slug ? eventSlugQuery(eventFragment) : eventIdQuery(eventFragment)} variables={query}>
      {({
        loading, error, data,
      }) => {
        if (error) return <Error error={error} />
        if (loading) return <LinearProgress color="secondary" />

        const {
          eventCollection,
          event,
        } = data

        const eventData = event || (
          eventCollection
          && eventCollection.items
          && eventCollection.items[0]
        )
        if (eventData) {
          return <Event {...eventData} />
        }
        return <Error statusCode={404} />
      }}
    </Query>
  )
}

EventPage.getInitialProps = async ({ query }) => ({ query })


EventPage.propTypes = {
  query: PropTypes.object,
}

export default EventPage
