import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import RolexClock from '@components/rolex-clock'
import useStyles from './leaderboard-landing.styles'

const LeaderboardLandingHeader = (props) => {
  const {
    headline,
  } = props
  const styles = useStyles()

  return (
    <Box className={styles.outer}>
      <Box py="1rem">
        <Container className={styles.wrapper}>
          <Typography
            variant="h2"
            variantMapping={{ h2: 'h1' }}
            className={styles.heading}
          >{headline}
          </Typography>
          <Box className={styles.clockWrapper}>
            <RolexClock showExternalAd />
          </Box>
        </Container>
      </Box>
      <RolexClock showExternalAd isMobile />
    </Box>
  )
}

LeaderboardLandingHeader.propTypes = {
  headline: PropTypes.string,
}

LeaderboardLandingHeader.defaultProps = {}

export default LeaderboardLandingHeader
